import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34e26787"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cover_page = _resolveComponent("cover-page")!
  const _component_allgemein = _resolveComponent("allgemein")!
  const _component_anmerkungen = _resolveComponent("anmerkungen")!
  const _component_arbeitsflaeche_mast = _resolveComponent("arbeitsflaeche-mast")!
  const _component_zuwegung_mast = _resolveComponent("zuwegung-mast")!
  const _component_fremdleitung = _resolveComponent("fremdleitung")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_cover_page, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"]),
      _createVNode(_component_allgemein, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"]),
      _createVNode(_component_anmerkungen, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"]),
      _createVNode(_component_arbeitsflaeche_mast, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"]),
      _createVNode(_component_zuwegung_mast, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"]),
      _createVNode(_component_fremdleitung, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"])
    ])
  ]))
}