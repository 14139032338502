
import { defineComponent, PropType, ref } from "vue";
import BaDifference from "./BaDifference.vue"
export default defineComponent({
    components: { BaDifference },
    props: {
        baDifferences: {
            type: Object as PropType<any>,
            required: true
        }
    },
    setup(props) {
        const BaTabs: any = [];

        let activeTab = ref("")

        props.baDifferences.forEach((difference: { absolutePath: string; }) => {
            const tabPath = difference.absolutePath.split(".")[1]
            if (!BaTabs.includes(tabPath)) { BaTabs.push(tabPath) }
        });

        const setTabActive = (tab: any) => {
            activeTab = tab
        }

        return { BaTabs, setTabActive, activeTab }
    }
})
