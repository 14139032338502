

import GeoJsonInput from "@/components/hzba/Base/GeoJsonInput.vue";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import { GeoJsonConfig } from "@/models/ba/interfaces/IFrage";
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea } from "@ionic/vue";
import { geometry, unitsFactors } from "@turf/turf";
import { PropType, defineComponent } from "vue";
export default defineComponent({
  name: "HzbaGeoJsonInput",
  components: {
    GeoJsonInput,
    InputError,
    UnitChip,
    InputLabel,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonTextarea
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined,
    },
    identifier: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    mapConfig: {
      type: Object as PropType<GeoJsonConfig>,
      required: true
    },
    minMax: {
      type: Object as PropType<any>,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChanged(e: any) {
      if (e.features.length >= 1) {
        // props.mapConfig = null
      }
      emit("update:modelValue", e);
    }

    return {
      onChanged
    }
  }
})
