
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import {
  useIdentifierFrageFinder,
  getAuswahlLabel,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import { useBauflaechen } from './useBauflaechen';

import PdfChip from '../Components/PdfChip.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';

export default defineComponent({
  name: 'CoverPage',
  components: {
    PdfA4Page,
    PdfChip,
    BauflaechenFooter,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const {
      getZuwegungBewertungen,
      getArbeitsFlaecheBewertungen,
      getFremdleitungenBewertungen,
      summarizeFremdleitungenBewertung,
      summarizeZuwegungBewertung,
      summarizeArbeitsFlaechenBewertung,
      summarizeOverall,
    } = useBauflaechen();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getZuwegungBewertungen,
      getArbeitsFlaecheBewertungen,
      getFremdleitungenBewertungen,
      amountFremdleitung: summarizeFremdleitungenBewertung(props.ba)['amount'],
      colorFremdleitung: summarizeFremdleitungenBewertung(props.ba)['color'],
      amountZuwegung: summarizeZuwegungBewertung(props.ba)['amount'],
      colorZuwegung: summarizeZuwegungBewertung(props.ba)['color'],
      amountArbeitsFlaechen: summarizeArbeitsFlaechenBewertung(props.ba)[
        'amount'
      ],
      colorArbeitsFlaechen: summarizeArbeitsFlaechenBewertung(props.ba)[
        'color'
      ],
      amountOverall: summarizeOverall(props.ba)['amount'],
      colorOverall: summarizeOverall(props.ba)['color'],
      getAuswahlLabel,
    };
  },
});
