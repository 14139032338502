
import PdfHzbaTemplate from "./Bericht_Heizung/PdfHzbaTemplate";
import RippisteinBauflaechen from "./Bericht_Bauflaechen/RippIsteinBauflaechen.vue";

import {PropType} from "vue/dist/vue";
import Bestandsaufnahme from "../../models/ba/Bestandsaufnahme";
import Immobilie from "../../models/immobilie.model";
import {defineComponent} from "vue";

export default defineComponent({
  name: "PdfTemplates",
  components: {PdfHzbaTemplate, RippisteinBauflaechen},
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props, { emit }) {

    console.log(props.ba);
    console.log(props.immobilie);

  }
})
