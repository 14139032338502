
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { defineComponent } from "vue";
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
export default defineComponent({
  name: "PdfHzbaOverviewGeneralInfo",
  components: { PdfChip },

  props: {
    data: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    hzba: {
      type: Bestandsaufnahme,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    lang: {
      type: String,
      default: "de",
    },
    isTableOfContent: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    const isBeschildert = computed(() => {
      return useIdentifierFrageFinder(
        props.hzba,
        "hzba.heizzentrale-tab.heizraumtuer-group.heizraumtuer-modal[FIRST_INSTANCE].heizraumtuer.beschildert"
      )?.eingabeBoolean;
    });

    const waermeerzeugers = computed(() => {
      const parent = useIdentifierFragenblockFinder(
        props.hzba,
        "hzba.waermeerzeuger-tab"
      );
      return parent?.getMultipleFragenblockInstances("waermeerzeuger");
    });

    return {
      positionTranslated,
      t,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,

      isBeschildert,
      waermeerzeugers,
    };
  },
});
