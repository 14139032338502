import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-825a78ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block lg:flex h-full lg:h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar ios-fix"
}
const _hoisted_3 = { class: "properties-sidebar-wrapper" }
const _hoisted_4 = { class: "properties-sidebar" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "text-2xl md:text-3xl lg:mx-auto mb-0-important" }
const _hoisted_8 = { class: "absolute bottom-0 right-0 pr-4 pb-2" }
const _hoisted_9 = {
  id: "map-root",
  ref: "mapEl",
  style: {"width":"100%"},
  class: "h-full lg:h-screen"
}
const _hoisted_10 = {
  key: 0,
  class: "properties-preview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_property_card = _resolveComponent("property-card")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isMinLarge)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_IonButton, {
                        fill: "clear",
                        color: "dark",
                        class: "px-0 -ml-4",
                        size: "small",
                        onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('goBack'))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_IonIcon, {
                            icon: _ctx.arrowBack,
                            size: "large"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.t("immobilie.kartenAnsicht")) + " (" + _toDisplayString(_ctx.properties.length) + ") ", 1)
                    ]),
                    _createElementVNode("div", null, _toDisplayString(_ctx.t("immobilie.aktualisiertAm")) + ": " + _toDisplayString(_ctx.lastFetch), 1)
                  ]),
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (item) => {
                    return (_openBlock(), _createBlock(_component_property_card, {
                      key: item.id,
                      property: item,
                      class: _normalizeClass([_ctx.hoveredProp && _ctx.hoveredProp.id === item.id ? 'hovered' : '', "w-full max-w-md m-0 mb-4"]),
                      "custom-click-function": () => _ctx.cardClicked(item)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_IonButton, {
                            size: "small",
                            fill: "flat",
                            onClick: () => _ctx.openProperty(item)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("immobilie.oeffnen")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["property", "class", "custom-click-function"]))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, null, 512)
    ]),
    (!_ctx.isMinLarge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.propertySlideList.length > 0 && !_ctx.changesLoading)
            ? (_openBlock(), _createBlock(_component_ion_slides, {
                key: 0,
                ref: "swiper",
                pager: "",
                options: {
        speed: 300,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: (_ctx.propertySlideList.length > 1)
      },
                onIonSlideReachStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.jumpToEnd())),
                onIonSlideReachEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.jumpToStart()))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertySlideList, (item) => {
                    return (_openBlock(), _createBlock(_component_ion_slide, {
                      key: item.id,
                      class: "mb-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_property_card, {
                          property: item,
                          "is-slide-mode": "",
                          class: "w-full max-w-md ml-12 mr-12"
                        }, null, 8, ["property"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["options"]))
            : _createCommentVNode("", true),
          (_ctx.propertySlideList.length > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "swiper-button-next md:right-32",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.slideNext()))
              }))
            : _createCommentVNode("", true),
          (_ctx.propertySlideList.length > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "swiper-button-prev md:left-32",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.slidePrev()))
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}