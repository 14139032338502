
import AButton from "@/components/Base/AButton";
import ProgressIndicator from "@/components/ProgressIndicator";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { IonButton, IonLabel, IonSegment, IonSegmentButton, useIonRouter } from "@ionic/vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "BaTabs",
  components: {AButton, ProgressIndicator, IonSegment, IonSegmentButton, IonLabel, IonButton},
  props: {
    selectedView: {
      type: String,
      default: undefined
    },
  },
emits: ['showSummary', 'goTo', 'changeTab'],
  setup(props: any, { emit }: { emit: any}) {
    const store = useStore();
    const router = useRouter();
    const prevRoute = ref();

    const ionRouter = useIonRouter();


    const { isMinLarge } = useScreenSize();

    let currentTab = '';

    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const tabs = computed<Fragenblock[] | undefined>(() => {
      return ba.value?.fragenblocks
    })

    watch([tabs], (newVal) => {
      tabs.value?.forEach(el => el.setupUnlockDetector())
    }, { immediate: true })

    const changeRoute = (e: any) => {
      // console.log("change route", e.detail.value)
      if (currentTab !== e.detail.value && e.detail.value) {
        currentTab = e.detail.value;
        // router.replace(`/ba/${ba.value!.id}/${e.detail.value}`)

        emit('changeTab', e.detail.value);
        // ionRouter.navigate(`/ba/${ba.value!.id}/${e.detail.value}`, 'root', 'replace', undefined);
      }
    }

    const changeRouteDesktop = (tab: Fragenblock) => {
      // router.replace(`/ba/${ba.value!.id}/${tab.identifier}`)
      // ionRouter.navigate(`/ba/${ba.value!.id}/${tab.identifier}`, 'root', 'replace', undefined);

      emit('changeTab', tab.identifier);
    }

    const goToSummary = () => {
      emit('showSummary');
    }

    return {ba, tabs, goToSummary, changeRoute, changeRouteDesktop, isMinLarge}
  }
}
