
import { useI18n } from "vue-i18n";
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from "moment";

import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
// import PdfHzbaCustomInputPage from "@components/Pdf/Components/PdfHzbaCustomInputPage.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import useUser from "@/composables/useUser";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import {
  ExtendedMSERow,
  MSERow,
} from "@/models/ba/interfaces/IGenericPdfDataRow";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfFMDataTable from "@/components/Pdf/Components/PdfFMDataTable.vue";
import { Computed } from "vuex";
import PdfGeneralInfo from "./PdfHzbaOverviewGeneralInfo.vue";
import {
  getNestedBreadcrumbTitles,
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { logoWindows } from "ionicons/icons";
import { MaengelReport } from "@/models/ba/MaengelReport";
import PdfPageBreak from "@/components/Pdf/Components/PdfPageBreak.vue";
import PdfHzbaOverviewPage from "@/components/Pdf/Bericht_Heizung/PdfHzbaOverviewPage.vue";
import useHzbaPdfReport from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";
import PdfHzbaMaengelPage from "@/components/Pdf/Bericht_Heizung/PdfHzbaMaengelPage.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import PdfCustomInputPage from "@/components/Pdf/Components/PdfCustomInputPage.vue";

export default defineComponent({
  name: "PdfHzbaTemplate",
  components: {
    PdfCustomInputPage,
    PdfHzbaMaengelPage,
    PdfHzbaOverviewPage,
    PdfPageBreak,
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    PdfMSEDataTable,
    PdfFMDataTable,
    PdfColorCodes,
    PdfMSEValues,
    PdfImageRow,
    PdfDataSectionSummary,
    PdfA4Page,
    PdfPageHeader,
    PdfAconLogo,
    PdfGeneralInfo,
    PdfChip,
    // PdfHzbaCustomInputPage
    // PdfMangelsOverview
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });

    const useHzbaPdf = useHzbaPdfReport(props.lang);
    const uUser = useUser();

    const pagecounterFirstPages = 1;
    const pagecounterMaengel = ref(0);
    const pagecounterCustomInput = ref(0);
    const pagecounterDetailPages = computed(() => {
      return useHzbaPdf.detailPages.value?.length || 0;
    });
    const pagecounterAppointments = 1;
    const pagecounterTotalPages = computed(() => {
      return (
          pagecounterFirstPages +
          pagecounterDetailPages.value +
          pagecounterMaengel.value +
          pagecounterCustomInput.value +
          pagecounterAppointments
      );
    });

    const pagecounterStartDetailPage = computed(() => {
      return pagecounterFirstPages + 1;
    });
    const pagecounterStartMaengelPage = computed(() => {
      return pagecounterFirstPages + pagecounterDetailPages.value + 1;
    });
    const pagecounterStartCustomInput = computed(() => {
      return pagecounterStartMaengelPage.value + pagecounterMaengel.value;
    })
    const pagecounterStartAppointments = computed(() => {
      return (
          pagecounterFirstPages +
          pagecounterDetailPages.value +
          pagecounterMaengel.value +
          pagecounterCustomInput.value +
          1
      );
    });

    const tableOfContentMSEData = computed<ExtendedMSERow[] | undefined>(() => {
      const overviewTable = useHzbaPdf.tableOfContentFragenblocks.value
          // .filter(el => el.identifier !== 'uebersicht-tab')
          .map(
          (el) => {
            let page = pagecounterStartDetailPage.value;

            const waermeerzeugerPageAdd =
                useHzbaPdf.waermeerzeugers.value?.length || 0;
            const unterstationPageAdd = useHzbaPdf.unterstation.value ? 1 : 0;
            const anlagenKomponentenPageAdd = useHzbaPdf.anlagenKomponenten.value
                ? 1
                : 0;
            const customInputPageAdd = useHzbaPdf.customInputs.value ? 1 : 0;

            switch (el.identifier) {
              case "heizzentrale-tab":
                page += 0;
                break;
              case "waermeerzeuger-tab":
                page += 1;
                break;
              case "anlagenkomponenten-tab":
                page += waermeerzeugerPageAdd + anlagenKomponentenPageAdd;
                break;
              case "unterstation":
                page +=
                    waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd;
                break;
              case "nachspeisung-tab":
                page +=
                    waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd +
                    1;
                break;
                case "uebersicht-tab":
                waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd +
                    customInputPageAdd +
                    1;
                  break;
              default:
                page = 0;
            }

            return el.toPdfDataRow(props.lang, page.toString());
          }
      );

      overviewTable?.push({
        title: "Mangelübersicht",
        description1: pagecounterStartMaengelPage.value,
        description2: undefined,
      });

      return overviewTable;
    });

    console.log("Test");
    console.log(useHzbaPdf);

    return {
      getPhotoUrl,
      tableOfContentMSEData,
      pagecounterMaengel,
      pagecounterTotalPages,
      appointments: useHzbaPdf.termine,
      moment,
      tableOfContentFragenblocks: useHzbaPdf.tableOfContentFragenblocks,
      detailPages: useHzbaPdf.detailPages,
      customInputs: useHzbaPdf.customInputs,
      // getCustomInputBilder: useHzbaPdf.getCustomInputBilder,
      user: uUser.user,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,
      t,
      pagecounterStartDetailPage,
      pagecounterStartMaengelPage,
      pagecounterStartAppointments,
      pagecounterStartCustomInput,
      pagecounterCustomInput,
    };
  },
});
