
import { getNestedBreadcrumbTitles, useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { useStore } from '@/composables/useTypedStore';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        difference: {
            type: Object,
            required: true
        },
        activeTab: {
            type: String,
            required: true
        }
    },
    setup(props: any, ctx: any) {
        const { t } = useI18n({ useScope: "global" });
        const store = useStore();

        const seeEverything = ref(false)

        const ba = computed(() => {
            return store.state.currentHzba.currentBa;
        });

        const frage = useIdentifierFrageFinder(ba.value, props.difference.absolutePath)

        console.log("Frage:      ",  frage)

        return { getNestedBreadcrumbTitles, t, ba, seeEverything, frage }
    },
})
