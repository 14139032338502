
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';

export default defineComponent({
  name: 'isVorhandenCharAndLabel',
  components: {},
  props: {
    label: { type: String, required: true, default: 'Label' },
    fragesPath: {
      type: String,
      required: true,
      default: '',
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    return {
      t,
      useIdentifierFrageFinder,
    };
  },
});
