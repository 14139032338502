
import Toolbar from "@/components/Navigation/Toolbar";
import {
IonBackButton,
IonButton,
IonButtons,
IonContent,
IonHeader,
IonIcon,
IonModal,
IonPage,
IonTitle,
onIonViewWillEnter
} from '@ionic/vue';
import { computed, defineComponent, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import useAlert from "@/composables/useAlert";
import useCurrentBaState from "@/composables/useCurrentBaState";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { store } from "@/store/store";
import {
arrowBack,
close,
} from 'ionicons/icons';
import { ref } from "vue";

export default defineComponent({
  name: 'BaseModal',
  components: {Toolbar, IonContent, IonHeader, IonBackButton, IonTitle, IonModal, IonIcon, IonButton, IonButtons, IonPage },
  props: {
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    askBeforeClose: {
      type: Boolean,
      default: false
    },
    hideSave: {
      type: Boolean,
      default: false
    },
    goBackMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'save', 'open'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const { ba } = useCurrentBaState();
    const isOpen = ref(false);
    const alert = useAlert();
    const { isLockedByCurrentDeviceAndUser } = Bestandsaufnahme;

    function setOpen(val: boolean) {
      isOpen.value = val;

      if (isOpen.value) {
        emit('open')
      }

      if (!isOpen.value) {
        emit('close');
      }
    }

    function askForClose() {
      if (props.askBeforeClose && ba?.value?.isDownloaded) {
        alert.showAdv(
            'Aktion abbrechen?',
            'Sind Sie sicher, dass Sie diese Eingabe abbrechen möchten? Ihre ungespeicherten Daten gehen verloren.',
            [
              {
                text: 'Weiterbearbeiten'
              },
              {
                text: 'Dateneingabe abbrechen',
                handler: () => {
                  setOpen(false);
                }
              },
            ]
        )
      } else {
        setOpen(false);
      }
    }

    onIonViewWillEnter(() => {
      setOpen(props.open)
    })

    watch(() => props.open, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        setOpen(newVal)
      }
    });


    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      isReadonly,
      isOpen,
      setOpen,
      close,
      arrowBack,
      askForClose,
      t
    }
  }
});
