

import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useBauflaechen } from './useBauflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import IsVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';

export default defineComponent( {
  name: 'Bauwerke',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    IsVorhandenCharAndLabel,
    TngHeader,
    BauflaechenFooter,
    OnetoFiveImageDiv
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup ( props: any ) {
    const { t } = useI18n( { useScope: 'global' } );
    const uUser = useUser();
    const { getBauwerkePages } = useBauflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getBauwerkePages,
    };
  },
} );
