
import { strapiApiUrl } from '@/api/api';
import PDFViewer from '@/components/Base/PdfViewer';
import Toolbar from '@/components/Navigation/Toolbar';
import PdfPageSkeleton from '@/components/PdfPageSkeleton.vue';
import PdfReader from "@/components/PdfReader/PdfReader.vue";
import useDownloadHelper from '@/composables/useDownloadHelper';
import StrapiMedia from '@/models/photo/strapi-media.model';
import { getPdfUrl } from '@/utilities/get-media-url';
import {
IonButton,
IonButtons,
IonCard,
IonContent,
IonHeader,
IonIcon,
IonSpinner,
IonTitle,
IonToolbar,
modalController,
} from '@ionic/vue';
import { close, download, share, trashOutline } from "ionicons/icons";
import { PropType, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PdfModal',
  components: {
    PdfReader,
    PdfPageSkeleton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCard,
    IonButton,
    IonButtons,
    IonIcon,
    IonSpinner,
    PDFViewer,
    Toolbar,
  },
  props: {
    pdf: {
      type: Object as PropType<StrapiMedia>,
      required: true,
    },
    deleteMode: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });
    const onClose = async () => {
      await modalController.dismiss();
    };

    const pdfsrc = ref();
    const numPages = ref();
    const isLoaded = ref();
    const isError = ref();

    const { canShare, downloadFile, isProcessing } = useDownloadHelper();

    function downloadResource() {
      const url: string | undefined = getPdfUrl(props.pdf);
      url && downloadFile(url, props.pdf.name);
    }

    return {
      close,
      strapiApiUrl,
      canShare,
      onClose,
      getPdfUrl,
      pdfsrc,
      numPages,
      download,
      downloadResource,
      isLoaded,
      isProcessing,
      isError,
      trashOutline,
      t,
      share,
    };
  },
});
