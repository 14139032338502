

import AdditionalMaengel from "@/components/hzba/Base/AdditionalMaengel.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaGroupCtas from "@/components/hzba/Base/HzbaGroupCtas.vue";
import DebugFragenblock from "@/components/Other/DebugFragenblock.vue";
import { useStore } from "@/composables/useTypedStore";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { FragenblockJson } from "@/models/ba/interfaces/IFragenblock";
import { openHzbaModal } from "@/utilities/modal-helper";
import { IonButton } from "@ionic/vue";
import _ from 'lodash';
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import HzbaGroup from "./Base/HzbaGroup";
import HzbaModalItem from "./Base/Input/HzbaModalItem";
import HzbaModalItemBlock from "./Base/Input/HzbaModalItemBlock";
import HzbaSeparator from "./Base/Input/HzbaSeparator";
export default defineComponent({
  name: "RecursiveForm",
  components: {
    DebugFragenblock,
    AdditionalMaengel,
    HzbaGroupCtas, HzbaForm, HzbaSeparator, HzbaModalItemBlock, HzbaModalItem, HzbaGroup, IonButton, },
  props: {
    parentFragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    },
    modalDepth: {
      type: Number,
      default: -1,
    },
    saveOnFrageInputChanged: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const activeDebug = ref();

    const fragenblocks = computed(() => {
      return props.parentFragenblock && props.parentFragenblock.fragenblocks
    })


    /**
     * Todo does this change have any performance impact? if so, find another solution or reactivate the legacy code
     */
    watch(() => props.parentFragenblock, async (newVal) => {
      // fragenblocks.value?.forEach(el => el.setupFragenblockMinimumInstances())
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupUnlockDetector())
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupShowDetector())
    }, { immediate: true })
    // watch(() => fragenblocks.value, async (newVal) => {
    //   // fragenblocks.value?.forEach(el => el.setupFragenblockMinimumInstances())
    //   fragenblocks.value?.forEach(el => el.setupUnlockDetector())
    //   fragenblocks.value?.forEach(el => el.setupShowDetector())
    // }, { immediate: true })


    const setDataFromCallback = (parentFragenblock: Fragenblock, index: number, i: number, callbackData: { action: string; data: FragenblockJson }) => {

      const parentPath = parentFragenblock.path;

      if (callbackData.action === 'DUPLICATE') {
        parentFragenblock.duplicateFragenblockInstance(new Fragenblock(callbackData.data, parentPath, props.modalDepth));
      } else if (callbackData.action === 'DELETE') {
        parentFragenblock.deleteFragenblockInstance(new Fragenblock(callbackData.data, parentPath, props.modalDepth));
      } else if (callbackData.action === 'SAVE') {
        const ind: number | undefined = parentFragenblock.fragenblocks?.findIndex(el => el.uid === callbackData.data.uid);

        if (ind !== undefined && parentPath) {
          parentFragenblock.setDirty();
          parentFragenblock.fragenblocks![ind] = new Fragenblock(callbackData.data, parentPath, props.modalDepth);
        } else {
          console.error('setDataFromCallback caused an error. Index of element not found?', ind, parentPath);
        }
      }

      /**
       * After a modal was saved, we need to refresh the show/unlockdetectors because they might reference to an outdated (and removed) object now.
       */
      console.log("Recursive form after save...");
      fragenblocks.value?.forEach(el => el.setupUnlockDetector())
      fragenblocks.value?.forEach(el => el.setupShowDetector())
    }

    const saveLocally = _.debounce(() => {
      if (props.saveOnFrageInputChanged) {
        store.dispatch("currentHzba/saveHzbaLocally");

      }
    }, 500)


    // const changeSuffix = (fragenblock: Fragenblock, suffix: string) => {
    //   if (fragenblock.config) {
    //     fragenblock.config.label = suffix;
    //   }
    //   store.dispatch("currentHzba/saveHzbaLocally");
    // }

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const enabledDevMode = computed(() => store.state.app.enabledDevMode );

    return {
      fragenblocks,
      setDataFromCallback,
      t,
      saveLocally,
      isReadonly,
      openHzbaModal,
      activeDebug,
      enabledDevMode,
    }
  }
})
