import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b5e7dba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "h-full flex flex-col lg:px-0" }
const _hoisted_3 = {
  style: {"background":"white"},
  class: "md:px-4 2xl:px-0 pt-6"
}
const _hoisted_4 = {
  key: 0,
  class: "row items-center container px-4 md:px-0"
}
const _hoisted_5 = { class: "my-2 flex-1" }
const _hoisted_6 = { class: "text-2xl md:text-3xl lg:mx-auto" }
const _hoisted_7 = { class: "container px-4 md:px-0" }
const _hoisted_8 = {
  key: 1,
  class: "container px-4 md:px-0 md:px-4 2xl:px-0",
  style: {"flex":"1 1 auto"}
}
const _hoisted_9 = { class: "py-4 block md:flex flex-row-reverse text-xl items-center" }
const _hoisted_10 = { class: "flex-1 md:text-right" }
const _hoisted_11 = {
  key: 2,
  class: "flex flex-row items-center justify-center"
}
const _hoisted_12 = {
  key: 2,
  class: "container-fluent",
  style: {"flex":"1 1 auto"}
}
const _hoisted_13 = {
  key: 0,
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDialogButton = _resolveComponent("UserDialogButton")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ListIcon = _resolveComponent("ListIcon")!
  const _component_AIconButton = _resolveComponent("AIconButton")!
  const _component_MapIcon = _resolveComponent("MapIcon")!
  const _component_sort_by = _resolveComponent("sort-by")!
  const _component_offline_hint = _resolveComponent("offline-hint")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_property_list = _resolveComponent("property-list")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_property_map = _resolveComponent("property-map")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.routeName !== 'propertiesMap')
        ? (_openBlock(), _createBlock(_component_toolbar, {
            key: 0,
            title: "Immobilien"
          }, {
            trailing: _withCtx(() => [
              _createVNode(_component_UserDialogButton)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.routeName === 'propertiesMap')
        ? (_openBlock(), _createBlock(_component_toolbar, {
            key: 1,
            class: "lg:hidden"
          }, {
            leading: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_button, {
                  slot: "start",
                  fill: "clear",
                  "data-cy": "button-goBack",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleView('list')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.arrowBack,
                      size: "large",
                      color: "light"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                (_ctx.routeName === 'propertiesList' || !_ctx.isMinLarge)
                  ? (_openBlock(), _createBlock(_component_Searchbar, {
                      key: 0,
                      "sort-term": _ctx.sortTerm,
                      "onUpdate:sortTerm": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortTerm) = $event)),
                      "search-term": _ctx.searchTerm,
                      "onUpdate:searchTerm": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchTerm) = $event)),
                      "toolbar-mode": true,
                      class: "w-full pr-4",
                      "sort-terms": _ctx.supportedSortTerms
                    }, null, 8, ["sort-term", "search-term", "sort-terms"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.routeName === 'propertiesList')
              ? (_openBlock(), _createBlock(_component_ion_refresher, {
                  key: 0,
                  slot: "fixed",
                  onIonRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doRefresh($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_refresher_content)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.routeName === 'propertiesList')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.t("immobilie.uebersichtImmobilies")) + " ", 1)
                    ]),
                    _createVNode(_component_AIconButton, { "data-cy": "button-listView" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ListIcon, {
                          active: _ctx.routeName === 'propertiesList',
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleView('list')))
                        }, null, 8, ["active"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_AIconButton, { "data-cy": "button-mapView" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MapIcon, {
                          active: _ctx.routeName === 'propertiesMap',
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleView('map')))
                        }, null, 8, ["active"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.routeName === 'propertiesList')
                  ? (_openBlock(), _createBlock(_component_Searchbar, {
                      key: 0,
                      "search-term": _ctx.searchTerm,
                      "onUpdate:searchTerm": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.searchTerm) = $event)),
                      class: "mb-4"
                    }, null, 8, ["search-term"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.routeName === 'propertiesList')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_sort_by, {
                        "sort-term": _ctx.sortTerm,
                        "onUpdate:sortTerm": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sortTerm) = $event)),
                        "sort-terms": _ctx.supportedSortTerms
                      }, null, 8, ["sort-term", "sort-terms"])
                    ]),
                    _createElementVNode("div", null, " Letzte Aktualisierung: " + _toDisplayString(_ctx.lastFetch), 1)
                  ]),
                  _createVNode(_component_offline_hint, { class: "mb-4" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Aktuell besteht keine Internetverbindung. Nur offline gespeicherte Immobilien werden angezeigt. ")
                    ]),
                    _: 1
                  }),
                  (!_ctx.propertiesLoaded)
                    ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_property_list, {
                        key: 1,
                        properties: _ctx.properties
                      }, null, 8, ["properties"])),
                  (_ctx.showLoadMore)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_AButton, {
                          type: "submit",
                          "btn-primary": true,
                          class: "px-16 py-6 mb-2 text-lg",
                          "data-cy": "button-login",
                          style: {"margin-top":"32px !important","margin-left":"0"},
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loadMore()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("buttons.loadMore")), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_ctx.routeName === 'propertiesMap')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (!_ctx.propertiesLoaded)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createVNode(_component_Skeleton)
                        ]))
                      : (_openBlock(), _createBlock(_component_property_map, {
                          key: 1,
                          properties: _ctx.properties,
                          onGoBack: _cache[11] || (_cache[11] = () => _ctx.$router.push('/properties'))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Searchbar, {
                              "search-term": _ctx.searchTerm,
                              "onUpdate:searchTerm": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.searchTerm) = $event)),
                              "sort-term": _ctx.sortTerm,
                              "onUpdate:sortTerm": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sortTerm) = $event)),
                              "sort-terms": _ctx.supportedSortTerms
                            }, null, 8, ["search-term", "sort-term", "sort-terms"])
                          ]),
                          _: 1
                        }, 8, ["properties"]))
                  ]))
                : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}