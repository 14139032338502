import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb7977ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-6" }
const _hoisted_2 = { class: "mt-12 ml-6 mr-6 mb-12 flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HzbaTextareaInput = _resolveComponent("HzbaTextareaInput")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    title: _ctx.t('hzba.vorschau.fazit'),
    open: _ctx.open,
    "ask-before-close": true,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close())),
    onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_HzbaTextareaInput, {
                modelValue: _ctx.currentFazit,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentFazit) = $event)),
                label: _ctx.t('hzba.vorschau.fazit'),
                placeholder: '...',
                readonly: _ctx.isReadonly,
                limit: _ctx.maxFazitLength
              }, null, 8, ["modelValue", "label", "placeholder", "readonly", "limit"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.fazitTooLong ? 'red' : '', "flex-1"])
              }, null, 2),
              (!_ctx.isReadonly)
                ? (_openBlock(), _createBlock(_component_AButton, {
                    key: 0,
                    "btn-primary": true,
                    class: "text-lg py-5 px-8",
                    shape: "round",
                    disabled: !(_ctx.currentFazitLength > 0) || _ctx.fazitTooLong,
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.bestaetigen")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "open"]))
}