
import AButton from "@/components/Base/AButton";
import AFlatButton from "@/components/Base/AFlatButton.vue";
import AInputMLStyle from "@/components/Base/AInputMLStyle";
import Toolbar from "@/components/Navigation/Toolbar";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import SelectLanguage from "@/components/selectLanguage";
import useLogoutAlert from "@/composables/User/useLogoutAlert";
import useAlert from "@/composables/useAlert";
import useAuth from "@/composables/useAuth";
import { isDevEnvironment } from "@/composables/useEnvDetector";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { getLocalMetaData } from "@/models/local-meta-data";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { logger } from "@/utilities/logging";
import { popoverHelper } from "@/utilities/popover-helper";
import {
IonButton,
IonButtons,
IonContent,
IonImg,
IonPage,
onIonViewWillEnter,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import {
helpers,
minLength,
required
} from "@vuelidate/validators";
import { computed, defineComponent, reactive, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { environment } from "../../environments/environment";

export default defineComponent({
  name: "Settings",
  components: {
    AFlatButton,
    AButton,
    Toolbar,
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    AInputMLStyle,
    UserDialogButton,
    SelectLanguage,
  },
  setup() {
    const store = useStore();
    const { user, updateProfileImage } = useUser();
    const { t } = useI18n({ useScope: "global" });
    const router = useRouter();
    const { isMaxSmall } = useScreenSize();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    const isDev = isDevEnvironment();

    const auth = useAuth();
    const alert = useAlert();

    const env = environment;
    const processEnv = process.env;

    const { openPhotoDialog } = usePhotoRecording();

    const backendBuildVersion = computed(() => {
      return getLocalMetaData("backendBuildVersion");
    });

    const uploadProfileImage = async () => {
      const photoRes = await openPhotoDialog(t, 1, {hideFile: true});
      photoRes.success && photoRes.data && photoRes.data.length > 0 && await updateProfileImage(photoRes.data[0]);
    };

    const changePasswordData = reactive({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      userId: user.value?.id
    });

    const rules = {
      currentPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
      },
      newPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
        minLength: helpers.withMessage(
          t("helper.passwortLaenge"),
          minLength(8)
        ),
        goodPassword: helpers.withMessage(
          t("helper.passwortSicherheit"),
          (password: any) => {
            return (
              password.length >= 6 &&
              (/[a-z]/.test(password) || /[A-Z]/.test(password)) &&
              /[0-9]/.test(password)
            );
          }
        ),
      },
      confirmNewPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
        sameAsPassword: helpers.withMessage(
          t("einstellungen.passwortNichtIdent"),
          (confPassword: string) => {
            return (
              confPassword === changePasswordData.newPassword
            );
          }
        ),
      },
    };

    const changePasswordForm = useVuelidate(rules, {
      currentPassword: toRef(changePasswordData, "currentPassword"),
      newPassword: toRef(changePasswordData, "newPassword"),
      confirmNewPassword: toRef(changePasswordData, "confirmNewPassword"),
    });

    const onChangePassword = async () => {
      changePasswordForm.value.$touch();

      try {
        await auth.changePassword(changePasswordData);
        await alert.show(t("einstellungen.passwortGeändert"), "");
      } catch (error: any) {
        if (error.response?.data.message === "wrong-current-password") {
          await alert.show(t("einstellungen.falschesPasswort"), "");
        } else {
          await alert.show(t("alerts.unerwarteterFehlerHead"), t("alerts.unerwarteterFehlerText"));

          logger.error(`Resetting password failed: ${error}`);
        }
      }

      changePasswordData.currentPassword = "";
      changePasswordData.newPassword = "";
      changePasswordData.confirmNewPassword = "";
      changePasswordForm.value.$reset();
    };


    function goToLogs() {
      router.push('/logs')
    }

    return {
      logout: () => useLogoutAlert(router, t),
      getPhotoUrl,
      user,
      openPopover: popoverHelper,
      env,
      processEnv,
      uploadProfileImage,
      backendBuildVersion,
      t,
      changePasswordForm,
      onChangePassword,
      goToLogs,
      rowClass: 'flex mb-4 text-sm',
      isDev,
      isMaxSmall
    };
  },
});
