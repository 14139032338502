
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, PropType } from "vue";
import Property from "../../models/immobilie.model";
import { useRouter } from "vue-router";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import AButton from "@/components/Base/AButton.vue";
import getLocalization from "@/utilities/get-localization";
import CloudOfflineIcon from "@/components/Base/CloudOfflineIcon.vue";

export default defineComponent({
  name: "PropertyCard",
  components: {
    CloudOfflineIcon,
    AImage,
    AButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
    customClickFunction: {
      type: Function,
      default: undefined,
    },
    isSlideMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hover'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const router = useRouter();

    const goToDetailPage = () => {
      router.push({
        path: `/property/${props.property.id}`
      });
    };

    const hover = () => {
      emit('hover');
    }

    const cardClicked = () => {
      if (props.customClickFunction) {
        props.customClickFunction()
        return;
      }
      goToDetailPage()
    }

    /**
     * Support only one label in slide mode but with a notice like "Heizungsbestandsaufnahme +2"
     * otherwise return full array of ba's that are abgeschlossen or freigegeben
     */
    const baLabels = computed(() => {

      const labels: any[] = [];
      let extraLabelsCounted = 0;
      props.property?.bestandsaufnahmes?.forEach((el) => {

        const name = el.name && getLocalization(el.name);
        if (!name) { return; }

        if (labels.indexOf(name) === -1 && (el.status === "ABGESCHLOSSEN" || el.status === "FREIGEGEBEN")) {
          if (props.isSlideMode && labels.length >= 1) {
            extraLabelsCounted += 1;
          } else {
            labels.push(name)
          }
        }
      })

      if (extraLabelsCounted > 0) {
        labels[0] += ` +${extraLabelsCounted}`;
      }

      if (labels.length > 1) {
        return ['Gebäudeaufnahme']
      }

      return labels;
    })

    return { router, goToDetailPage, getPhotoUrl, hover, cardClicked, t, baLabels };
  },
});
