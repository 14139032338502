
import AButton from "@/components/Base/AButton.vue";
import DebugFrage from "@/components/Other/DebugFrage.vue";
import HzbaGeoJsonInput from "@/components/hzba/Base/Input/HzbaGeoJsonInput.vue";
import MangelItem from "@/components/hzba/Base/MangelItem.vue";
import { useStore } from "@/composables/useTypedStore";
import { Frage } from "@/models/ba/Frage";
import { openHzbaModal } from "@/utilities/modal-helper";
import { computed, ref, watch } from "vue";
import { PropType, defineComponent } from "vue-demi";
import { useI18n } from "vue-i18n";
import HzbaBaseInput from "./Input/HzbaBaseInput.vue";
import HzbaChooseInput from "./Input/HzbaChooseInput.vue";
import HzbaModalItemBlock from "./Input/HzbaModalItemBlock.vue";
import HzbaPhotoInput from "./Input/HzbaPhotoInput.vue";
import HzbaSelectInput from "./Input/HzbaSelectInput.vue";
import HzbaTextareaInput from "./Input/HzbaTextareaInput.vue";

export default defineComponent({
  name: "HzbaForm",
  components: {
    HzbaGeoJsonInput,
    DebugFrage,
    AButton,
    MangelItem,
    HzbaChooseInput,
    HzbaSelectInput,
    HzbaPhotoInput,
    HzbaBaseInput,
    HzbaTextareaInput,
    HzbaModalItemBlock,
  },
  emits: ['inputChanged'],
  props: {
    formFrages: {
      type: Object as PropType<Frage[]>,
      required: true,
    },
  },
  setup(props, { emit }) {

    const { t } = useI18n({ useScope: 'global' })

    const store = useStore();

    const activeDebug = ref();

    watch(() => props.formFrages, (newVal, oldVal) => {
      props.formFrages.forEach(el => {
        el.setupShowDetector();
        el.setupComputedValues();

        watch(() => el.getCurrentInput(), (newEl, oldEl) => {
          console.log("setdirty")
          el.setDirty();
          emit('inputChanged')

        } )
      })
    }, { immediate: true })

    const isReadonly = ( isFrageReadOnly: boolean | undefined ) => {
      return isFrageReadOnly || store.getters['currentHzba/isBaReadonly'];
    }

    const getInputType = (eingabeTyp: string) => {
      switch (eingabeTyp) {
        case "uhrzeit":
          return "time";
        case "datum":
          return "date";
        case "zahl":
          return "number";
        default:
          return "text";
      }
    }


    const enabledDevMode = computed(() => store.state.app.enabledDevMode );

    return {
      openHzbaModal,
      isReadonly,
      activeDebug,
      getInputType,
      enabledDevMode,
      t
    }
  }
});
