
import AButton from "@/components/Base/AButton";
import FaqItem from "@/components/Faq/faq-item.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useFaqs } from "@/composables/useFaqs";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import {
IonButton,
IonButtons,
IonCard,
IonContent,
IonImg,
IonPage,
onIonViewWillEnter,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "FaqPage",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    UserDialogButton,
    IonCard,
    FaqItem
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const { user } = useUser();
    const store = useStore();

    const { faqs } = useFaqs();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    return {
      user,
      faqs,
      t
    };
  },
});
