import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c52fdc70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfChip = _resolveComponent("PdfChip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        'plus' ===
        _ctx.useIdentifierFrageFinder(_ctx.ba, _ctx.fragesPath)['eingabeAuswahlWert']
      )
      ? (_openBlock(), _createBlock(_component_PdfChip, {
          key: 0,
          color: "green",
          "mangel-amount-mode": "",
          value: "1",
          "pdf-mode": ""
        }))
      : _createCommentVNode("", true),
    (
        'o' === _ctx.useIdentifierFrageFinder(_ctx.ba, _ctx.fragesPath)['eingabeAuswahlWert']
      )
      ? (_openBlock(), _createBlock(_component_PdfChip, {
          key: 1,
          color: "blue",
          "mangel-amount-mode": "",
          value: "1",
          "pdf-mode": ""
        }))
      : _createCommentVNode("", true),
    (
        'minus' ===
        _ctx.useIdentifierFrageFinder(_ctx.ba, _ctx.fragesPath)['eingabeAuswahlWert']
      )
      ? (_openBlock(), _createBlock(_component_PdfChip, {
          key: 2,
          color: "yellow",
          "mangel-amount-mode": "",
          value: "1",
          "pdf-mode": ""
        }))
      : _createCommentVNode("", true),
    (
        'x' === _ctx.useIdentifierFrageFinder(_ctx.ba, _ctx.fragesPath)['eingabeAuswahlWert']
      )
      ? (_openBlock(), _createBlock(_component_PdfChip, {
          key: 3,
          color: "red",
          "mangel-amount-mode": "",
          value: "1",
          "pdf-mode": ""
        }))
      : _createCommentVNode("", true)
  ]))
}