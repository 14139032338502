
import AButton from "@/components/Base/AButton";
import licenseReport from "@/components/Impressum/licenseReport.json";
import Toolbar from "@/components/Navigation/Toolbar";
import useUser from "@/composables/useUser";
import { arrowBack } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

// import Bibliothek from "@/models/bibliothek.model";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useStore } from "@/composables/useTypedStore";
import {
IonButton,
IonButtons,
IonCard,
IonCardContent,
IonCardHeader,
IonCardTitle,
IonCol,
IonContent,
IonGrid,
IonIcon,
IonPage,
IonRow,
IonText,
onIonViewWillEnter,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImpressumPage",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonContent,
    UserDialogButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonText,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
  },
  // props: {
  //   bibliothek: {
  //     type: Object as PropType<Bibliothek>,
  //       required: true
  //   }
  // },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { user } = useUser();
    const store = useStore();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    const router = useRouter();
    const goBackToLandingPage = () => {
      router.push("/properties");
    };

    return {
      user,
      t,
      licenseReport,
      arrowBack,
      goBackToLandingPage,
    };
  },
});
