import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b3a9d9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-left":"36px","margin-right":"36px"} }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_toolbar, { title: "Logs" }, {
        leading: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_a_button, { onClick: _ctx.refreshLogs }, {
              default: _withCtx(() => [
                _createTextVNode(" refresh ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["wrapper", log.stage]),
                  onClick: ($event: any) => (_ctx.expandedItem === index ? _ctx.expandedItem = -1 : _ctx.expandedItem = index)
                }, [
                  _createElementVNode("h3", null, _toDisplayString(log.logs[0]), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.moment(log.date).format('HH:mm:ss - DD.MM.YYYY')), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.expandedItem === index ? 'expanded' : 'collapsed')
                  }, [
                    _createElementVNode("pre", null, _toDisplayString(log.logs), 1)
                  ], 2)
                ], 10, _hoisted_2))
              }), 128))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}