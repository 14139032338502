

import { openHzbaModal } from "@/utilities/modal-helper";
import { useI18n } from 'vue-i18n';
import HzbaBaseModal from "@/components/Base/BaseModal";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import {computed, defineComponent, PropType} from "vue";

import {store} from "@/store/store";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import {IonContent, modalController} from "@ionic/vue";
import {Fragenblock} from "@/models/ba/Fragenblock";
import HzbaModalItemBlock from "@/components/hzba/Base/Input/HzbaModalItemBlock.vue";
import {
  getNestedBreadcrumbTitles,
  useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import HzbaDeleteDuplicateItem from "@/components/hzba/Base/Input/HzbaDeleteDuplicateItem.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import { logger } from "@/utilities/logging";

export default defineComponent({
  name: "FragenblockModal",
  components: {
    RecursiveForm,
    HzbaDeleteDuplicateItem,
    HzbaModalItemBlock,
    HzbaForm,
    HzbaBaseInput, HzbaGroup, HzbaBaseModal, IonContent, SectionWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // ba: {
    //   type: Object as PropType<Heizungsbestandsaufnahme>,
    //   required: true,
    // },
    fragenblockBase: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    modalDepth: {
      type: Number,
      default: 0,
    }
  },
  emits: ["close", "save"],
  setup(props: any) {
    console.log("setup fragenblock modal")
    const { t } = useI18n({ useScope: 'global' });

    store.commit( 'currentHzba/addModalBa', props.modalDepth);

    const tmpBa = computed(() => {
      // console.log("FragenblockModal", store.state.currentHzba.modalBas, props.modalDepth);
      return store.state.currentHzba.modalBas[props.modalDepth];
    })

    const fragenblock = computed<Fragenblock | undefined>(() => {
       return tmpBa.value && useIdentifierFragenblockFinder(tmpBa.value, props.fragenblockBase.path, undefined);
    })


    // const hm = new GenericBAModal(props.fragenBlock)

    function getFrages(fragenBlock: Fragenblock) {
      return fragenBlock.frages;
    }

    async function cancel() {
      store.commit( 'currentHzba/removeModalBa')
      await modalController.dismiss();
    }

    async function save() {
      console.log("Saving Fragenblock Modal...")
      // logger.info()
      // console.log("TEST?!", fragenblock.value, fragenblock.value?.toJson());

      const valid = fragenblock.value?.isFormValid();
      const dirty = fragenblock.value?.isFormDirty();


      if (valid) {
        fragenblock.value?.submit();
        // console.log("fragenblock submit save", fragenblock.value)

        await modalController.dismiss({
          action: 'SAVE',
          data: await fragenblock.value?.copyJson()
        });

        store.commit( 'currentHzba/removeModalBa')
      } else {
        fragenblock.value?.setShowErrors();
      }

      console.log("SAVE...")
      console.log("valid..", valid)
      console.log("dirty..", dirty)
    }

    async function deleteInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();
      store.commit( 'currentHzba/removeModalBa')

      await modalController.dismiss({
        action: 'DELETE',
        data: copiedFragenblock,
      })
    }

    async function duplicateInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();
      store.commit( 'currentHzba/removeModalBa')

      await modalController.dismiss({
        action: 'DUPLICATE',
        data: copiedFragenblock,
      })
    }


    // const setDataFromCallback = (parentFragenblock: any, index: number, i: number, data: any) => {
    //   console.log("setDataFromCallback", fragenBlock, index, i);
    //   console.log("setDataFromCallback - data", data);
    //   const parent = fragenBlock.value!.fragenBlocks![index];
    //   fragenBlock.value!.fragenBlocks![index].fragenBlocks![i] = new Fragenblock(data, parent.path, HZBA_MODE.MODAL);
    //
    //   console.log("setDataFromCallback afterwards", fragenBlock);
    //   console.log("setDataFromCallback afterwards", store.state.currentHzba.modalHzba)
    // }

    const getNestedBreadcrumbTitle = (path: string) => {
      console.log("PATHH :    ",path )
      return path && tmpBa.value && getNestedBreadcrumbTitles(tmpBa.value, path);
    }

    return {
      fragenblock,
      tmpBa,
      t,
      getNestedBreadcrumbTitle,
      getFrages,
      cancel,
      deleteInstance,
      save,
      duplicateInstance,
      openHzbaModal,
    };
  },
});
