
import AButton from "@/components/Base/AButton";
import AImage from "@/components/Base/AImage.vue";
import CloudOfflineIcon from "@/components/Base/CloudOfflineIcon.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
  HzbaStatus,
  translatedHzbaStatus,
} from "@/models/ba/interfaces/IBestandsaufnahme";
import Immobilie from "@/models/immobilie.model";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { logger } from "@/utilities/logging";
import { openBaseModal } from "@/utilities/modal-helper";
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import {
  alertCircleOutline,
  calendarOutline,
  checkmarkCircle,
  cloudOfflineOutline,
  ellipseOutline,
  ellipsisHorizontalCircle,
  locationOutline,
  timeOutline,
} from "ionicons/icons";
import moment from "moment";
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HzbaCard",
  components: {
    AButton,
    CloudOfflineIcon,
    PdfChip,
    AImage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonIcon,
    IonLabel,
    IonBadge,
    IonChip,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const user = useUser();
    const router = useRouter();
    const immobilie = computed(() => {
      return Immobilie.getters('getPropOrFallbackProp')(props.ba.immobilie);
    });
    const ba = ref(props.ba);
    watch(ba, () => {
      console.log('+++ ba changed +++,', props.ba);
    })
    const openBericht = (e: any, pdf: any) => {
      e.stopImmediatePropagation();
      openBaseModal("pdf.view", { pdf: pdf });
    };

    const openMaengel = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.uebersicht", { ba: props.ba });
    };

    const relevantMaengellist = computed(() => {
      if (props.ba && Array.isArray(props.ba.mangellists)) {
        return props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
      }
      return [];
    })

    const openMaengelInImmobilie = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.immobilie.uebersicht", { ba: props.ba });
    };

    const recommendedBericht = (ba: any) => {
      if (ba.berichts && ba.berichts.length > 0) {
        const bericht = ba.berichts.find((el: any) =>
          el.kennzeichen.endsWith("-de")
        );
        return bericht && bericht.data;
      }
      return ba.bericht;
    };

    const recommendedMangelliste = (ba: any) => {
      if (ba.mangellists && ba.mangellists.length > 0) {
        // console.log('ba.mangellists: ', ba.mangellists[0].mangelvorlage.kategorie);
      }
      return ba.mangellists;
    };

    const goToBa = async (e: any) => {
      e.stopImmediatePropagation();
      if (!user.isImmobilienverwalter()) {
        await router.push("/ba/" + props.ba.id );
        logger.defaultMeta.currentBa = props.ba.id
        logger.info(`User opened BA ${props.ba.id}`);
      }
    };

    return {
      immobilie,
      getPhotoUrl,
      openBericht,
      alertCircleOutline,
      checkmarkCircle,
      relevantMaengellist,
      ellipseOutline,
      ellipsisHorizontalCircle,
      cloudOfflineOutline,
      HzbaStatus,
      calendarOutline,
      moment,
      locationOutline,
      timeOutline,
      recommendedBericht,
      recommendedMangelliste,
      translatedHzbaStatus,
      t,
      openMaengel,
      openMaengelInImmobilie,
      goToBa,
      user
    };
  },
});
