
import useUser from "@/composables/useUser";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { popoverHelper } from "@/utilities/popover-helper";
import SettingsIcon from "../../../public/assets/mlIcons/SettingsIcon.vue";
import { defineComponent, ref, computed } from "vue";
import { IonButton, IonButtons, IonContent, IonImg, IonPage } from "@ionic/vue";
import {useRouter} from "vue-router";
import AIconButton from "@/components/Base/AIconButton.vue";

export default defineComponent({
  name: "UserDialogButton",
  components: {
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    SettingsIcon,
    AIconButton
  },
  setup() {
    const { user } = useUser();
    const router = useRouter();
    const isActive = ref(false);
    const routeName = computed(() => { return router.currentRoute.value.name; });

    function goToSettings() {
      router.push('/settings')
    }

    return {
      goToSettings,
      user,
      openPopover: popoverHelper,
      getPhotoUrl,
      isActive,
      routeName
    };
  },
});
