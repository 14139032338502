
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import { getPhotoUrl } from '@/utilities/get-media-url';

export interface ImageObjects {
  image: any;
  title: string;
  width: 'double' | undefined;
}

export default defineComponent({
  name: 'OneToFiveImageDiv',
  components: { UniversalPdfImageRow },
  props: {
    imageObjects: {
      type: Array,
      required: false,
    },
    fragesPath: {
      type: String,
      required: true,
      default: '',
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const arrayWithFiveElementsAndImages: any[] = [[], [], [], [], []];
    let areImagesTaken = false;
    //this is a way
    if (
      props.imageObjects?.value != null ||
      props.imageObjects?.value != undefined
    ) {
      areImagesTaken = true;
      const a = toRef(props, 'imageObjects');
      for (let i = 0; i <= a.value.length; i++) {
        arrayWithFiveElementsAndImages[i] = a.value[i];
      }
    } else if (props.ba != undefined && props.fragesPath != undefined) {
      const images = useIdentifierFrageFinder(props.ba, props.fragesPath)?.[
        'eingabeMedien'
      ];
      if (undefined !== images) {
        if (images?.length >= 1) {
          areImagesTaken = true;
        }
        for (let i = 0; i <= images.length; i++) {
          arrayWithFiveElementsAndImages[i] = images[i];
        }
      }
    }
    return {
      getPhotoUrl,
      user: uUser.user,
      t,
      moment,
      arrayWithFiveElementsAndImages,
      areImagesTaken,
    };
  },
});
