
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import CoverPage from './CoverPage.vue';
import Allgemein from './Allgemein.vue';
import ArbeitsflaecheMast from './ArbeitsflaecheMast.vue';
import Fremdleitung from './Fremdleitung.vue';
import ZuwegungMast from './ZuwegungMast.vue';
import Anmerkungen from './Anmerkungen.vue';
import Bauwerke from './Bauwerke.vue';
import Grabenquerungen from './Grabenquerungen.vue';


export default defineComponent({
  name: 'PdfRippisteinBauflaechenTemplate',
  components: {
    CoverPage,
    Allgemein,
    ArbeitsflaecheMast,
    ZuwegungMast,
    Fremdleitung,
    Anmerkungen,
    Bauwerke,
    Grabenquerungen
  },
  props: {
     ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
    };
  },
});
