
import { useI18n } from 'vue-i18n';
import {computed, defineComponent, PropType, ref} from "vue";
import {
  IonItem,
  IonButton,
  IonIcon,
  IonCol,
  IonImg,
  IonRow,
  IonGrid,
  IonThumbnail,
  modalController, toastController,
} from "@ionic/vue";
import { camera, close, trash } from "ionicons/icons";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import StrapiMedia, { instanceOfStrapiImage } from "@/models/photo/strapi-media.model";
import {getPhotoUrl, instanceOfPhoto} from "@/utilities/get-media-url";
import { deleteFile } from "@/api/UploadApi";
import {openBaseModal} from "@/utilities/modal-helper";
import {store} from "@/store/store";
import AImage from "@/components/Base/AImage.vue";
import AButton from "@/components/Base/AButton.vue";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError.vue";
import {getPhotoMinMaxLabel} from "@/utilities/helper";
import { Device } from "@capacitor/device";

export default defineComponent({
  name: "HzbaPhotoInput",
  components: {
    InputError,
    AImage,
    AButton,
    IonItem,
    IonRow,
    IonGrid,
    IonIcon,
    IonCol,
    IonImg,
    IonThumbnail,
    IonButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<(StrapiMedia | Blob)[]>,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    minMax: {
      type: Object,
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => []
    },
    isPdfUploadType: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const { openPhotoDialog } = usePhotoRecording();
    const modalPhotoView = ref(false);

    const limit = computed(() => {
      if (!props.minMax?.max) {
        return -1;
      }

      return props.minMax.max - (props.modelValue?.length || 0)
    })
    const maxAllowed = () => {
      const reachedMaxAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      if (reachedMaxAllowed) {
        return true
      } else {
        return false
      }
    }
    const onTakePhoto = async () => {
      console.log('onTakePhoto triggered');
      const notAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      // isAllowed = !notAllowed;
      if (notAllowed) {

        const toast = await toastController
            .create({
              message: notAllowed,
              duration: 2000
            })
        return toast.present();
      }
      const photoRes = await openPhotoDialog(t, limit.value, props.isPdfUploadType ? { hidePhoto: true, hideGallery: true } : { hideFile: true });

      // const testRes = photo.webPath && await fetch(photo.webPath);
      // const test = testRes && await testRes.blob();
      // if (photoRes.messageKey === 'portraitOnly') {
      //   const toast = await toastController
      //       .create({
      //         message: t('hzba.portraitOnly'),
      //         duration: 2000
      //       })
      //   await toast.present();
      // }

      console.log('photoRes success?', photoRes?.success)
      console.log('photoRes data?', photoRes?.data);
        if (photoRes.success && photoRes.data) {
        // console.log("pdf media", props.modelValue, ...photoRes.data);

        emit('update:modelValue', [...(props.modelValue ?? []), ...photoRes.data ]);
        emit('change');
      }
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    // const device = Device.getInfo();
    // console.log('+++device***', device);
    const deviceWidth = computed(() => {
      return window.innerWidth
    });
    // console.log('deviceWidth: ', deviceWidth.value);
    const extendedButton = computed(() => {
      return !((props.modelValue?.length ?? 0) > 0) && (deviceWidth.value >= 1024)
    })

    function openGallery(index: number){
      openBaseModal('gallery', {
        images: props.modelValue,
        isReadonly: isReadonly.value,
        title: 'Fotos',
            downloadEnabled: isReadonly.value,
        index,
        onDelete: (photo: StrapiMedia | Blob) => {
          if (instanceOfStrapiImage(photo)) {
            deleteFile(photo.id)
          }
          emit('update:modelValue', props.modelValue?.filter((_, i) => i !== index));
          emit('change');
          modalController.dismiss();
        },
      },
          () => ({}))
    }

    function openPdfReader(index: number) {
      const pdf = props.modelValue && props.modelValue[index];
      openBaseModal('pdf.view', {
        pdf: pdf,
        deleteMode: true,
        onDelete: (pdf: StrapiMedia | Blob) => {
          if (instanceOfStrapiImage(pdf)) {
            deleteFile(pdf.id)
          }
          emit('update:modelValue', props.modelValue?.filter((_, i) => i !== index));
          emit('change');
          modalController.dismiss();
        },
      })
    }

    return {
      camera,
      trash,
      close,
      onTakePhoto,
      getPhotoUrl,
      modalPhotoView,
      instanceOfPhoto,
      openGallery,
      isReadonly,
      t,
      extendedButton,
      openPdfReader,
      maxAllowed
    };
  },
});
