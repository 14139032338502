import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12030ad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-sm w-full" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "text-left" }
const _hoisted_5 = { class: "pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.t("hzba.pdf.datum", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.t("hzba.pdf.artdermassnahme", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.t("hzba.pdf.massnahme", 1, { locale: _ctx.lang})), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appointments, (appointment) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: appointment,
        class: "h-12"
      }, [
        _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.moment(appointment.date).format('YYYY-MM-DD')), 1),
        _createElementVNode("td", null, _toDisplayString(appointment.topic), 1),
        _createElementVNode("td", null, _toDisplayString(appointment.description), 1)
      ]))
    }), 128))
  ]))
}