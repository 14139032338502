
import { defineComponent, PropType } from "vue";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import PdfRowBase from "@/components/Pdf/Components/PdfRowBase.vue";
// import PdfImageCropped from "@/components/Pdf/Components/PdfImageCropped.vue";

export default defineComponent({
  name: "PdfRowMangel",
  components: {
    PdfRowBase,
    // PdfImageCropped,
    AImage,
    PdfChip,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      required: true,
    },
  },
  setup() {
    return {
      getPhotoUrl,
    };
  },
});
