
import { strapiUrl } from "@/api/api";
import AButton from "@/components/Base/AButton";
import InfoBanner from "@/components/Base/InfoBanner.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import PdfHzbaTemplate from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate.vue";
import PdfPreviewerAndCreator from "@/components/Pdf/PdfPreviewerAndCreator.vue";
import PdfTemplates from "@/components/Pdf/PdfTemplates.vue";
import ProgressIndicator from "@/components/ProgressIndicator";
import SignatureField from "@/components/Signature/SignatureField.vue";
import Tabs from "@/components/hzba/BaTabs.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import StatusProgress from "@/components/hzba/StatusProgress.vue";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { PdfBerichtManager } from "@/composables/pdfBerichtManager";
import useCurrentBaState from "@/composables/useCurrentBaState";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import getLocalization from "@/utilities/get-localization";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import {
IonBackButton,
IonButton,
IonButtons,
IonChip,
IonCol,
IonContent,
IonGrid,
IonIcon,
IonItem,
IonLabel,
IonList,
IonPage,
IonRow,
IonSegment,
IonSegmentButton,
toastController,
} from "@ionic/vue";
import { chevronForward, warningOutline } from "ionicons/icons";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import BaAllDifferences from "../BaAllDifferences.vue";
import AdditionalMaengel from "../Base/AdditionalMaengel.vue";

import moment from "moment";

export default defineComponent({
  name: "BaSummary",
  components: {
    PdfTemplates,
    PdfHzbaTemplate,
    PdfPreviewerAndCreator,
    OfflineHint,
    // SignatureWrapper,
    SignatureField,
    StatusProgress,
    HzbaBaseInput,
    Tabs,
    IonList,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonRow,
    IonIcon,
    IonCol,
    IonContent,
    IonSegment,
    IonItem,
    IonChip,
    IonSegmentButton,
    IonGrid,
    BaAllDifferences,
    AdditionalMaengel,
    InfoBanner
},
  setup(_) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const selectedView = ref("uebersicht");
    const { isMaxSmall } = useScreenSize();
    const uploadLanguages = ["en", "de"];
    const isUploading = ref(false);
    const isOffline = computed(() => {
      return store.getters["app/isOffline"];
    });
    const upgradeAvailable = ref();
    const baDifferences = ref({});
    const baUnsolvedMigrations = ref();
    const backups = ref();
    const showDifferencesJson = ref(false)
    const migrationSuccessfull = ref(true);
    const migrationStatusText  = ref("")
    const migrationFromTo = ref({})

    const {
      immobilie,
      isReadonly,
      isSubmittable,
      totalProgress,
      isNotSubmitted,
      ba,
    } = useCurrentBaState();

    const user = useUser();

    const { syncBestandsaufnahme, submitBestandsaufnahme, saveBaLocally } =
      useBestandsaufnahmeUpload();

    const {
      downloadBa,
      removeDownloadedBa,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
      migrateBa,
      isUpgradeAvailable,
        getBackupsForBa,
        runBackup,
    } = useBestandsaufnahmens();
    const pdfManager = new PdfBerichtManager(uploadLanguages);

    const finishHzba = () => {
      isUploading.value = true;
      submitBestandsaufnahme(pdfManager);
    };

    const downloadName = computed(() => {
      return (immobilie.value?.stadt + "_" + immobilie.value?.name + "_" + ba.value?.name?.de).replace(' ', '_');
    });

    const maengel = computed(() => {
      return ba.value && ba.value.getFiredMaengel();
    })

    const removedMangelsFromMigrations = computed(() => {
      return ba.value && ba.value.removedMangelsFromMigrations.map((el: any) => new Mangelzuordnung(el, '', undefined));
    })

    const fileName = `${immobilie.value?.strasse}, ${immobilie.value?.plz} ${immobilie.value?.stadt} - ${t('hzba.maengelliste.maengelliste')} ${moment(ba.value?.begehungsdatum).format('DD.MM.YYYY')}.csv`


    onMounted(async() => {
      const { success, fromVorlage, toVorlage} = await isUpgradeAvailable(ba.value!)
      upgradeAvailable.value = success
      if (success) { migrationFromTo.value = {fromVorlage:fromVorlage, toVorlage:toVorlage} }

      backups.value = await getBackupsForBa(ba.value!.id);
    })

    const maengelReport = computed(() => {
      return ba.value?.maengelReport();
    });

    const getMigrateBa = async (bestandsaufnahme: any, preview: any) => {
      if (ba.value?.isLocal) {
        const toast = await toastController.create({
            message: t('toasts.migrationLokaleÄnderungLöschen'),
            duration: 4000
        })
        await toast.present();
      } else {
        const { success, differences, performedChanges, unsolvedMigrations, message } = await migrateBa(bestandsaufnahme, preview)
        if (performedChanges) {
          const { success } =  await isUpgradeAvailable(ba.value!);
          upgradeAvailable.value = success
          const toast = await toastController.create({
            message: t('toasts.migrationErfolgreich'),
            duration: 4000
          })
          await toast.present();
          await store.dispatch('currentHzba/refetchCurrentBa', true);
        } else if (!success) {
          migrationSuccessfull.value = false;
          migrationStatusText.value = message;
          baUnsolvedMigrations.value = unsolvedMigrations;
        } else {
          baDifferences.value = differences;
        }
      }
    }

    return {
      selectedView,
      immobilie,
      objectCountHeight: 62,
      syncBestandsaufnahme,
      uploadLanguages,
      openBaseModal,
      getMigrateBa,
      baDifferences,
      isMaxSmall,
      user,
      upgradeAvailable,
      getLocalization,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
      isReadonly,
      getPhotoUrl,
      isOffline,
      isSubmittable,
      maengelReport,
      finishHzba,
      setGeneratedPdfFile: pdfManager.setGeneratedPdfFile,
      setupPdfViewerRef: pdfManager.setupPdfViewerRef,
      totalProgress,
      warningOutline,
      isUploading,
      chevronForward,
      ba,
      baseUrl: strapiUrl,
      baUnsolvedMigrations,
      t,
      downloadBa,
      removeDownloadedBa,
      saveBaLocally,
      runBackup,
      moment,
      backups,
      translatedHzbaStatus,
      showDifferencesJson,
      migrationSuccessfull,
      migrationStatusText,
      migrationFromTo,
      removedMangelsFromMigrations,
      maengel,
      fileName,
      downloadName
    };
  },
});
