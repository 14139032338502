import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c057572"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "text-center",
  "data-cy": "MSE-malus"
}
const _hoisted_2 = {
  class: "text-center",
  "data-cy": "MSE-hinweis"
}
const _hoisted_3 = {
  class: "text-center",
  "data-cy": "MSE-safety"
}
const _hoisted_4 = {
  class: "text-center",
  "data-cy": "MSE-efficiency"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfChip = _resolveComponent("PdfChip")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      _createElementVNode("th", null, _toDisplayString(_ctx.t("hzba.pdf.maluswert", 1, { locale: _ctx.lang })), 1),
      _createElementVNode("th", null, _toDisplayString(_ctx.t("hzba.pdf.hinweis", 1, { locale: _ctx.lang })), 1),
      _createElementVNode("th", null, _toDisplayString(_ctx.t("hzba.pdf.safety", 1, { locale: _ctx.lang })), 1),
      _createElementVNode("th", null, _toDisplayString(_ctx.t("hzba.pdf.effizienz", 1, { locale: _ctx.lang })), 1)
    ]),
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_1, [
        _createVNode(_component_PdfChip, {
          value: _ctx.data.malus?.value,
          color: _ctx.data.malus?.color,
          "pdf-mode": ""
        }, null, 8, ["value", "color"])
      ]),
      _createElementVNode("td", _hoisted_2, [
        _createVNode(_component_PdfChip, {
          value: _ctx.data.hinweis?.value,
          color: _ctx.data.hinweis?.color,
          "pdf-mode": ""
        }, null, 8, ["value", "color"])
      ]),
      _createElementVNode("td", _hoisted_3, [
        _createVNode(_component_PdfChip, {
          value: _ctx.data.safety?.value,
          color: _ctx.data.safety?.color,
          "pdf-mode": ""
        }, null, 8, ["value", "color"])
      ]),
      _createElementVNode("td", _hoisted_4, [
        _createVNode(_component_PdfChip, {
          value: _ctx.data.efficiency?.value,
          color: _ctx.data.efficiency?.color,
          "pdf-mode": ""
        }, null, 8, ["value", "color"])
      ])
    ])
  ]))
}