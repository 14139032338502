
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';

export default defineComponent({
  name: 'FirstPage',
  components: {},
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      moment,
      useIdentifierFrageFinder,
    };
  },
});
