import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05599ab2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_pdf_reader = _resolveComponent("pdf-reader")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { class: "mb-8" }, {
      default: _withCtx(() => [
        _createVNode(_component_toolbar, {
          title: _ctx.t('hzba.pdf.vorschauTitel'),
          "enable-desktop-nav": false
        }, {
          leading: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "data-cy": "photo-preview-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClose()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.close
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          trailing: _withCtx(() => [
            (!_ctx.deleteMode)
              ? (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 0,
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isProcessing)
                      ? (_openBlock(), _createBlock(_component_ion_spinner, {
                          key: 0,
                          color: "light"
                        }))
                      : (_openBlock(), _createBlock(_component_ion_button, {
                          key: 1,
                          "data-cy": "button-downloadResource",
                          onClick: _ctx.downloadResource
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.canShare ? _ctx.share : _ctx.download
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 1,
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDelete(_ctx.pdf)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.trashOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_ion_card, {
              key: 0,
              class: "p-8"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_1, _toDisplayString('hzba.pdf.fehlgeschlagen'), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_pdf_reader, {
          url: _ctx.getPdfUrl(_ctx.pdf)
        }, null, 8, ["url"])
      ]),
      _: 1
    })
  ], 64))
}